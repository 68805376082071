import React, { FunctionComponent } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseDomain, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import './purchasedomain.css'
import { Button, Col, Container, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css';
import { TiTick } from 'react-icons/ti';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import DialogBox from '../dialogBox';
import DialogBoxWithSpinner from '../dialogBoxWithSpinner';
import { getApiUrl } from '../../config/api';
import { createMultiverseStripeClient } from '../../utils/createMultiverseStripeClient';
import { TemplateSelectGrid } from '../templateSelectDialog';
import { onEntityDirty } from '../../utils/entitycache';


type PlanPrice = {
    stripeId?: string;
    currency: string;
    interval: "month" | "year";
    amount: number;
}

type PlanOption = {
    type: string;
    name: string;
    minMembers: number;
    maxMembers: number;
    payment: "free" | "oculus" | "stripe" | "contact";
    stripeId?: string;
    oculusId?: string;
    button: "create" | "trial" | "buy" | "contact" | "comingsoon" | "upgrade" | "current" | "unavailable" | "downgrade",
    enabled: boolean;
    monthlyPrice: PlanPrice;
    annualPrice: PlanPrice;
    currency: string;
    chargePerMember?: boolean
}

type GetPlansResults = {
    locale: string;
    currency: string;
    plans: PlanOption[];
    current?: {
        type: string;
        members: number;
    };
    default?: {
        type: string;
        members: number;
    }
}

type PurchaseDomainProps = {
    domain_id?: string; //if undefined, this is creating a domain
    title?: string;
    context?: string;
} & WithMultiverseApiProps & RouteComponentProps;

type PurchaseDomainState = {
    options: GetPlansResults | null;
    timespan: "month" | "year";
    suggestedType: string;
    selectedMembers: number;
    upgradeDialog?: { title: string, charges: { amount: number, description: string, currency: string }[], total: {amount:number, currency: string}, plan: PlanOption };
    downgradeDialog?: { plan: PlanOption };
    needOwnerDialog?: boolean;
    busy: boolean;
};

function getPlanBaseType(plan:PlanOption) { return plan.type.match(/\w*/)![0]; }

const SUBTITLES = {
    "free": (<>The simplest plan for small Metaverses</>),
    "premium": (<>Access to the full range of room templates plus video uploads for a comprehensive metaverse</>),
    "professional": (<>More streaming features and exclusive perks for the more advanced user</>),
    "business": (<>For organisations that need to scale and manage their Metaverse membership with enterprise controls or stream to a wide audience</>),
    "enterprise": (<>Most flexible plan for large enterprises with advanced management, enterprise grade security and much more</>)
}
//@ts-ignore
function getSubtitle(plan: PlanOption) { return SUBTITLES[getPlanBaseType(plan)]};

const CURRENCY_SYMBOLS = {
    "usd": "$",
    "gbp": "£"
}

const SHORT_TIMESPAN = {
    "month": "mo",
    "year": "yr"
}

const DESCRIPTION_HEADERS = {
    "free": (<>Features Include:</>),
    "premium": (<>Everything in Free plus:</>),
    "professional": (<>Everything in Premium plus:</>),
    "business": (<>Everything in Pro plus:</>),
    "enterprise": (<>Everything in Business plus:</>)
}
//@ts-ignore
function getDescriptionHeader(plan: PlanOption) { return DESCRIPTION_HEADERS[getPlanBaseType(plan)]};
 
const DESCRIPTION_LINES = {
    "free": [
        "Create your own metaverse",
        "Add and link rooms and upload images",
        "Host events",
        "Import content from Google Drive",
        "0.25GB free storage",
        "Access to a selection of virtual environments",
        "Purchase plots of land, set building prices and add decorative statues",
        "Unlimited visitors",
        "Unlimited members",
        "Invite members (managers) with a magic link"
    ],
    "premium": [
        "Upload videos to use in your Metaverse",
        "Play YouTube videos and live streams (Quest only)",
        "All XP earned increased by 15%*",
        "All LVL rewards earned increased by 3x*",
        "5GB of storage",
        "Over 60 Premium environments in a wide range of styles for showing your content",
        "Up to 5 Infiniverse storefronts from our extensive range of designs",
        "Unlimited managers",
        "Create exclusive resident-only metaverses accessible via your storefronts",
        "Invite members by email"
    ],
    "professional": [
        "20GB of storage",
        "Up to 15 Infiniverse storefronts",
        "All XP earned increased by 30%*",
        "All LVL rewards earned increased by 12x*",
        "Upload video using custom HLS and live stream links (m3u8)",
    ],
    "business": [
        "32GB of storage",
        "Up to 50 Infiniverse storefronts",
        "All XP earned increased by 50%*",
        "All LVL rewards earned increased by 36x*"
    ],
    "enterprise": [
        "Work with developers for your tailored solution",
        "Custom 3D content",
        "Fully customizable environments",
        "128GB of storage",
        "Unlimited Infiniverse storefronts",
        "Live stream webcams and desktops to up to 12 people",
        "Unlimited members",
        "Unlimited locations",
        "Per Metaverse custom avatars",
        "Dedicated infrastructure options available",
        "Oculus for business support"
    ]
}
function getDescriptionLines(plan: PlanOption) {
    //@ts-ignore
    const lines = DESCRIPTION_LINES[getPlanBaseType(plan)];

    return <Container fluid>
        {lines.map((x: string) => 
        <Row>
            <Col className="pd-description-tick col-auto" ><TiTick/></Col>
            <Col className="pd-description-text">{x}</Col>
        </Row>)}
    </Container>
}

//tab buttons used to choose between annual/monthly plans
//styled to have no borders and things so don't change size or look focussed
type TabButtonProps = {
    selected?: boolean,
    onClick?: () => void
}
const TabButton:FunctionComponent<TabButtonProps> = (props) => {
    return (
      <Button
        variant={props.selected ? "dark" : "light"}
        className="no-outline-button"
        style={{width:"100%", borderColor:"transparent"}}
        onClick={props.onClick}
        >
        {props.children}
      </Button>
    );
};

class _PurchaseDomain extends React.Component<PurchaseDomainProps, PurchaseDomainState> {
    constructor(props: PurchaseDomainProps) {
        super(props);
        this.state = {
            options: null,
            timespan: "year",
            suggestedType: "",
            selectedMembers: 1,
            busy: false
        };
    }

    //mounting loads available upgrade options
    async componentDidMount(): Promise<void> {
        const { domain_id, context, multiverse: { get, post } } = this.props;

        //either load domain-creation options or domain-upgrade options depending on whether id is provided
        //for domains, also force a sync of subscription status in advance to ensure stripe matches up
        let options: GetPlansResults;
        if (domain_id) {
            await post(`/v2/domains/${domain_id}/stripe/sync`);
            options = await get(`/v2/domains/${domain_id}/getwebupgradeplans?context=${context || "upgrade"}`);
        } else {
            options = await get("/v2/domains/getwebcreateplans");
        }
        this.setState({
            options
        })

        //if default provided (which it normally should be), set it as suggested type. otherwise just
        //highlight the currently active one
        if(options.default) {
            this.setState({suggestedType: options.default.type, selectedMembers: options.default.members});
        } else if(options.current) {
            this.setState({suggestedType: options.current.type, selectedMembers: options.current.members});
        }
    }

    //return either monthly or annual price depending on current timespan selection
    getPlanPrice(plan: PlanOption) {
        switch(this.state.timespan) {
            case "month": { return plan.monthlyPrice; }
            case "year": { return plan.annualPrice; }
        }
    }

    //get monthly cost depending on time span selection (for annual timespan, divided by 12)
    getPlanMonthlyAmount(plan: PlanOption) {
        switch(this.state.timespan) {
            case "month": { return plan.monthlyPrice.amount; }
            case "year": { return plan.annualPrice.amount / 12; }
        }       
    }

    //get style to apply to a plan column based on whether it is disabled, suggested or otherwise
    getSelectionStyle(plan: PlanOption) {
        const { options, suggestedType: selectedType } = this.state;
        if (!options) {
            throw new Error("Options not loaded");
        }
        if(!plan.enabled) {
            return "pd-plan-col pd-plan-disabled";
        } else if(plan.type == selectedType) {
            return "pd-plan-col pd-plan-suggested";
        } else {
            return "pd-plan-col pd-plan-default";
        }      
    }

    //render function for the little tool tip to right of price that highlights monthly cost info
    renderPriceTooltop = (props: any, plan: PlanOption) => {
        return <Tooltip id="button-tooltip" {...props}>
            {plan.minMembers > 1 ? `Monthly price per Metaverse member, minimum ${plan.minMembers} members` : "Monthly price per Metaverse member"}
        </Tooltip>
    }
    renderMemberCountOnlyTooltop = (props: any, plan: PlanOption) => {
        return <Tooltip id="button-tooltip" {...props}>
            Minimum {plan.minMembers} members
        </Tooltip>
    }

    //renders localised plan price per month, plus an overlay trigger to display the price tool tip
    renderPlanPrice = (plan: PlanOption) => {
        const { options, timespan } = this.state;
        if (!options) {
            throw new Error("Options not loaded");
        }

        //hide price for enterprise
        if(plan.type.startsWith("enterprise")) {
            return <></>
        }

        //get monthly amount + convert to localised string
        const monthly_amount = this.getPlanMonthlyAmount(plan);
        const price_string = monthly_amount.toLocaleString(options.locale, {style: "currency", currency: options.currency} );

        //return "price/mo*" with tool tip
        return <>
            {price_string}
            {monthly_amount === 0 || !plan.chargePerMember ? "/mo" : 
            (<OverlayTrigger placement="top-start" delay={{ show: 0, hide: 400 }} overlay={(props: any) => this.renderPriceTooltop(props, plan)}>
                <span><u>/mo*</u></span>
            </OverlayTrigger>)}
        </>  
    }

    //render titles row
    renderTitles = (plans: PlanOption[]) => {
        return plans.map(x => 
            <Col key={`${x.type}-title`} className={`pd-title-col ${this.getSelectionStyle(x)}`}>
                {x.name}
            </Col>
        )
    }

    //render subtitles row
    renderSubTitles = (plans: PlanOption[]) => {
        return plans.map(x => 
            <Col key={`${x.type}-subtitle`} className={`pd-subtitle-col ${this.getSelectionStyle(x)}`}>
                {getSubtitle(x)}
            </Col>
        )
    }

    //render prices row
    renderPrices = (plans: PlanOption[]) => {
        const { options, timespan } = this.state;
        if (!options) {
            return;
        }

        return plans.map(x => 
            <Col key={`${x.type}-price`} className={`pd-price-col ${this.getSelectionStyle(x)}`}>
                { this.renderPlanPrice(x) }
            </Col>)
    }

    //handle main plan button being clicked
    onButtonClick = async(plan: PlanOption) => {
        const { domain_id, multiverse: { get, post, user }, history } = this.props;

        if(plan.button === "create" || plan.button === "trial") {
            
            //create/trial buttons just go straight to setup domain page
            this.props.history.push(`/setupdomain?plan=${plan.type}`);

        } else if(plan.button === "upgrade" || plan.button === "downgrade") {

            let stay_busy = false;
            try {
                this.setState({busy: true});

                //upgrade button needs to use stripe to either create a new subscription or upgrade existing one
                //first, get stripe api
                const stripe = await createMultiverseStripeClient();
                if(!stripe) {
                    throw new Error("Couldn't load stripe");
                }

                //load domain
                const domain = await get<MultiverseDomain>(`/v2/domains/${domain_id}`);
                
                //check if domain already has a subscription
                if(!domain.stripesubscription) {
                    if(plan.stripeId) {
                        if(domain.owner === user!.id) {
                            //plan is paid but no current subscription, so create a checkout session then use stripe to redirect to it
                            const checkout = await post<any>(`/v2/stripe/createupgradesession`, { 
                                domain: domain_id, 
                                plan: plan.type, 
                                priceId: this.state.timespan === "month" ? plan.monthlyPrice.stripeId : plan.annualPrice.stripeId 
                            })
                            stripe.redirectToCheckout({
                                sessionId: checkout.id
                            });
                            stay_busy = true; //no need to ever go un-busy as we're redirecting
                        } else {
                            //only owner can instigate a new subscription
                            this.setState({needOwnerDialog: true});
                        }
                    } else {
                        //plan is not paid and no current subscription so just a simple plan change
                        const cancel_result = await post<any>(`/v2/domains/${domain_id}/plan`, { 
                            newPlan: plan.type })       
                            
                        //redirect to domain front end
                        history.push(`/domains/${domain_id}`)                             
                    }
                } else {
                    if(plan.stripeId) {
                        //plan is paid and already have subscription, so get proration cost then set state to display it in modal dialog with confirm button
                        const proration = await post<any>(`/v2/stripe/getupgradeproration`, { 
                            domain: domain_id, 
                            plan: plan.type, 
                            priceId: this.state.timespan === "month" ? plan.monthlyPrice.stripeId : plan.annualPrice.stripeId })
                        this.setState({
                            upgradeDialog: {
                                title: plan.button === "upgrade" ? "Upgrade Plan" : "Downgrade Plan",
                                charges: proration.lines.data.map((x:any) => ({ amount: x.amount, currency: x.currency, description: x.description })),
                                total: { amount: proration.total, currency: proration.currency},
                                plan
                            }
                        })
                    } else {
                        if(domain.owner === user!.id) {
                            //plan is not paid but have a current subscription so need to cancel it
                            const cancel_result = await post<any>(`/v2/stripe/cancel`, { 
                                domain: domain_id, 
                                newPlan: plan.type })       

                            //to be safe (in the event of stripe web socket fails), trigger stripe sync immediately
                            await post(`/v2/domains/${domain_id}/stripe/sync`);

                            //redirect to domain front end
                            history.push(`/domains/${domain_id}`)                            
                        } else {
                            //only owner can instigate a new subscription
                            this.setState({needOwnerDialog: true});
                        }
                    }
                }

                //record plan has changed
                onEntityDirty(`${domain.uri}/plan`)
                
            } catch(err) {
                console.log(err);
            } finally {
                if(!stay_busy) {
                    this.setState({busy: false});                
                }
            }
        } else if(plan.button === 'contact') {
            this.props.multiverse.requestContactUs({
                defaultSubject: "Sales"
            })
        }
    }

    onDowngradeButtonClick = (plan: PlanOption) => {
        this.setState({downgradeDialog: { plan }})
    }

    //render the correct plan button based on setting
    renderButton = (plan: PlanOption) => {
        switch(plan.button) {
            case "create": { return <Button className={`pd-button pd-button-${plan.button}`} variant="secondary" onClick={()=>this.onButtonClick(plan)} disabled={!plan.enabled}>Get Started</Button>};
            case "trial": { return <Button className={`pd-button pd-button-${plan.button}`}  variant="primary" onClick={()=>this.onButtonClick(plan)} disabled={!plan.enabled}>Free Trial</Button>};
            case "buy": { return <Button className={`pd-button pd-button-${plan.button}`}  variant="primary" onClick={()=>this.onButtonClick(plan)} disabled={!plan.enabled}>Buy Now</Button>};
            case "upgrade": { return <Button className={`pd-button pd-button-${plan.button}`}  variant="primary" onClick={()=>this.onButtonClick(plan)} disabled={!plan.enabled}>Upgrade</Button>};
            case "contact": { return <Button className={`pd-button pd-button-${plan.button}`}  variant="primary" onClick={()=>this.onButtonClick(plan)} disabled={!plan.enabled}>Contact Us</Button>};
            case "comingsoon": { return <Button className={`pd-button pd-button-${plan.button}`}  variant="primary" onClick={()=>this.onButtonClick(plan)} disabled>Coming Soon</Button>};
            case "unavailable": { return <Button className={`pd-button pd-button-${plan.button}`}  variant="secondary" onClick={()=>this.onButtonClick(plan)} disabled>Unavailable</Button>};
            case "current": { return <Button className={`pd-button pd-button-${plan.button}`}  variant="secondary" onClick={()=>this.onButtonClick(plan)} disabled>Current Plan</Button>};
            case "downgrade": { return <Button className={`pd-button pd-button-${plan.button}`}  variant="secondary" onClick={()=>this.onDowngradeButtonClick(plan)} disabled={!plan.enabled}>Downgrade</Button>};
        }
    }

    //render buttons row
    renderButtons = (plans: PlanOption[]) => {
        return plans.map(x => 
            <Col key={`${x.type}-button`} className={`pd-button-col ${this.getSelectionStyle(x)}`}>
                { this.renderButton(x) }
            </Col>)
    }

    //render description headers row
    renderDescriptionHeaders = (plans: PlanOption[]) => {
        return plans.map(x => 
            <Col key={`${x.type}-descriptionheader`} className={`pd-descriptionheader-col ${this.getSelectionStyle(x)}`}>
                { getDescriptionHeader(x) }
            </Col>)
    }

    //render descriptions row
    renderDescriptions = (plans: PlanOption[]) => {
        return plans.map(x => 
            <Col key={`${x.type}-description`} className={`pd-description-col ${this.getSelectionStyle(x)}`}>
                { getDescriptionLines(x) }
            </Col>)
    }

    //render footers row
    renderFooters = (plans: PlanOption[]) => {
        return plans.map(x => 
            <Col key={`${x.type}-footer`} className={`pd-footer-col ${this.getSelectionStyle(x)}`}>

            </Col>)
    }

    //renders all rows for a list of plans
    renderPlansRows = (plans: PlanOption[]) => {
        return (
            <>
                <Row className="pd-plan-row">
                    {this.renderTitles(plans)}
                </Row>
                <Row className="pd-plan-row">
                    {this.renderSubTitles(plans)}
                </Row>
                <Row className="pd-plan-row">
                    {this.renderPrices(plans)}
                </Row>
                <Row className="pd-plan-row">
                    {this.renderButtons(plans)}
                </Row>
                <Row className="pd-plan-row">
                    {this.renderDescriptionHeaders(plans)}
                </Row>
                <Row className="pd-plan-row">
                    {this.renderDescriptions(plans)}
                </Row>
                <Row className="pd-plan-row">
                    {this.renderFooters(plans)}
                </Row>
            </>
        )
    }

    //renders all plans next to each other for a large screen
    renderPlansLargeScreen = () => {
        const { options } = this.state;
        if (!options) {
            return;
        }

        return (
            <Container className="d-none d-lg-block">
                {this.renderPlansRows(options.plans)}
                {this.renderPlansFootNote()}
            </Container>
        )
    }

    //renders all plans on separate rows for small screen, with suggested ones first
    renderPlansSmallScreen = () => {
        const { options } = this.state;
        if (!options) {
            return;
        }

        return (
            <Container className="d-lg-none">
                {options.plans.filter(x => x.type === this.state.suggestedType).map(x => this.renderPlansRows([x]))}
                {options.plans.filter(x => x.type !== this.state.suggestedType).map(x => this.renderPlansRows([x]))}
                {this.renderPlansFootNote()}
            </Container>
        )
    }

    renderPlansFootNote = () => {
        return (
            <Row>
                <Col>
                    *Does not apply during XP trial
                </Col>
            </Row>
        )
    }

    //renders classic loading spinner (used during initial plan options load)
    renderLoading() {
        return (
            <Container fluid>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>
        );
    }

    //confirmation button clicked from prorated upgrade dialog
    onConfirmUpgradeClicked = async (button: 0 | 1 | undefined) => {
        const { upgradeDialog, options } = this.state;
        const { domain_id, history, multiverse: { get, post } } = this.props;

        if(!upgradeDialog || !options) {
            throw new Error("Missing dialog or options info");
        }

        try {
            //apply the stripe upgrade 
            const res = await post<any>(`/v2/stripe/upgrade`, { 
                domain: domain_id, 
                plan: upgradeDialog.plan.type, 
                priceId: this.state.timespan === "month" ? upgradeDialog.plan.monthlyPrice.stripeId : upgradeDialog.plan.annualPrice.stripeId })

            //to be safe (in the event of stripe web socket fails), trigger stripe sync immediately
            await post(`/v2/domains/${domain_id}/stripe/sync`);

            //redirect to domain front end
            history.push(`/domains/${domain_id}`)

        } catch(err) {
            console.log(err);
        } finally {
            //hide dialog
            this.setState({upgradeDialog: undefined});
        }

    }

    //cancel button clicked from prorated upgrade dialog - just hides dialog
    onCancelUpgradeClicked = () => {
        this.setState({upgradeDialog: undefined});
    }

    //confirmation button clicked from prorated upgrade dialog
    onConfirmDowngradeClicked = async (button: 0 | 1 | undefined) => {
        const { downgradeDialog, options } = this.state;
        const { domain_id, history, multiverse: { get, post } } = this.props;

        if(!downgradeDialog || !options) {
            throw new Error("Missing dialog or options info");
        }

        try {
            await this.onButtonClick(downgradeDialog.plan)
        } catch(err) {
            console.log(err);
        } finally {
            //hide dialog
            this.setState({downgradeDialog: undefined});
        }

    }

    //cancel button clicked from prorated upgrade dialog - just hides dialog
    onCancelDowngradeClicked = () => {
        this.setState({downgradeDialog: undefined});
    }

    onCloseNeedOwnerClicked = () => {
        this.setState({needOwnerDialog: false})
    }

    onContactUsLinkClicked = () => {
        const { multiverse: { requestContactUs } } = this.props;       
        requestContactUs({
            defaultSubject: 'Support'
        });    
    }

    //main render
    render(): JSX.Element {
        const { upgradeDialog, downgradeDialog, options, busy, needOwnerDialog } = this.state;       

        //build main class name
        let cn = "purchase-domain";
        if(busy) {
            cn += " pd-busy";
        }

        return (
            <div className={cn}>
                {/*prorated upgrade dialog box*/}
                <DialogBoxWithSpinner
                    className="pd-upgrade-confirm-dialog"
                    title={upgradeDialog?.title || ""} 
                    show={upgradeDialog !== undefined} 
                    button0Text="Confirm"
                    cancelButtonText="Cancel"
                    onConfirm={this.onConfirmUpgradeClicked} 
                    onCancel={this.onCancelUpgradeClicked}
                    >
                    { upgradeDialog && options && <>
                        {upgradeDialog.charges.map(x => (
                            <>
                                <p className="mb-1">{x.description}:</p>
                                <p className="pl-2">{(x.amount/100).toLocaleString(options.locale, {style: "currency", currency: x.currency} )}</p>
                            </>
                        ))}
                        <p className="mb-1"><strong>Total to pay now:</strong></p>
                        <p className="pl-2 mb-1">{((upgradeDialog.charges[1].amount+upgradeDialog.charges[0].amount)/100).toLocaleString(options.locale, {style: "currency", currency: upgradeDialog.charges[1].currency} )}</p>
                    </>}
                </DialogBoxWithSpinner>

                {/*confirm downgrade box*/}
                <DialogBoxWithSpinner
                    className="pd-downgrade-confirm-dialog"
                    title="Downgrade Metaverse"
                    show={downgradeDialog !== undefined}
                    button0Text="Confirm"
                    cancelButtonText="Cancel"
                    onConfirm={this.onConfirmDowngradeClicked} 
                    onCancel={this.onCancelDowngradeClicked}                    
                    >
                    { downgradeDialog && options && <>
                        WARNING: Downgrading your Metaverse may result in aspects of it being <strong>disabled or entirely deleted</strong>. Are you sure you wish to continue?
                     </>}
                </DialogBoxWithSpinner>

                {/*notification that you need to be owner of a domain*/}
                <DialogBox
                    className="pd-needowner-dialog"
                    title="Change Subscription"
                    show={needOwnerDialog === true}
                    button0Text="Ok"
                    onConfirm={this.onCloseNeedOwnerClicked} 
                    >
                        Only the owner of a Metaverse can create or cancel a paid subscription. If this is an issue, please <a onClick={()=>this.onContactUsLinkClicked()} >Contact Us</a>.
                </DialogBox>

                {/*optional title header*/}
                {this.props.title && <Container className="pb-2">
                    <Row>
                        <Col>
                            <h4 style={{ textAlign: "center" }}>{this.props.title}</h4>
                        </Col>
                    </Row>
                </Container>}

                {/*loading spinner during initial options load*/}
                {!this.state.options && <>
                {this.renderLoading()}
                </>}

                {/*main interface*/}
                {this.state.options && this.state.options.plans.length > 0 && <div style={{position:"relative"}}>
                    {/*monthly/annual plan buttons*/}
                    <Container>
                        <Row className="pb-2" >
                            <Col className="pl-0 pr-1">
                                <TabButton selected={this.state.timespan === "month"} onClick={()=>{this.setState({timespan: "month"})}}>Monthly Plans</TabButton>
                            </Col>
                            <Col className="pl-1 pr-0">
                                <TabButton selected={this.state.timespan === "year"} onClick={()=>{this.setState({timespan: "year"})}}>Annual Plans</TabButton>
                            </Col>
                        </Row>
                    </Container>            
                    {/*plan lists for both small and large screens (toggled using css)*/}
                    {this.renderPlansLargeScreen()}
                    {this.renderPlansSmallScreen()}
                    {/*overlay displayed when busy during server call that covers whole main interface*/}
                    <div className="pd-loading-overlay">                  
                    </div>
                    <Container className="pb-2 pt-5">
                    <Row>
                        <Col>
                            <h4 style={{ textAlign: "center" }}>What Environments Are Included?</h4>
                        </Col>                    
                    </Row>
                    <Row>
                        <Col>
                            <TemplateSelectGrid/>
                        </Col>
                    </Row>
                    </Container>
                </div>}

                {/*notification of no plans interface*/}
                {this.state.options && this.state.options.plans.length == 0 && <div style={{position:"relative"}}>
                    <Container>
                        <Row>
                            <Col>We couldn't find any plans to adjust or upgrade for you. This is most likely as you are on a customized 
                            metaverse set up by Future Tech Labs or one of our partners. For assistance please contact your representative 
                            or
                            <Button variant="link" className="pd-contactus" onClick={(ev:any)=>this.props.multiverse.requestContactUs({defaultSubject: "Sales"})}>
                                contact us
                            </Button></Col>
                        </Row>
                    </Container>
                </div>}                
            </div>
        )
    }
}
export const PurchaseDomain = withRouter(withMultiverseApi(_PurchaseDomain));

export default PurchaseDomain;

